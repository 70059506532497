// user booking page 
// store info,  banner 
// time table
// booking info 
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import Header from './Header';
import qs from 'qs';
// calendar header
// store info
    // image, map, etcinfo
// schedule 
    // click booking info

import {
    PageButton,
    BlockDayElement
} from '../../../components/atoms'

import {
    BookingRequestModal,
    Calendar,
    AlertModal
} from '../../../components/modals'

import {
    getStoreBookingInfoRequest,
    getAdvertisementInfoRequest
} from '../../../apis/BookingApi';

import { 
    leadingZeros 
} from "../../../utilities/convert";

import {
    BookingUserType,
    AdvertisementResponseType
} from '../../../types';

import {
    StoreInfo,
    StoreMenuInfo,
    UserScheduleTable
} from '../../../components/organisms';

const Container = styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 50px;
    background: #FFFFFF;
`;

const TopButton = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    width: 32px;
`;

const Title = styled.div`
    color: #DB5299;
    font-weight: 500;
    height: 45px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
`;

const ButtonWrapper = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 11;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 20px;
`;

function Request(){
    const location = useLocation();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const link = query.link;
    
    const [ calendarView, setCalendarView ] = useState(false);
    const { storename } = useParams();
    const [ info, setInfo ] = useState<BookingUserType>();
    // const [ advertisementInfo, setAdvertisementInfo ] = useState<AdvertisementResponseType>();
    const [ influencerId, setInfluencerId ] = useState<number>(0);
    const [ advertisementId, setAdvertisementId ] = useState<number>(0);
    const [ isRequestModal, setIsRequestModal ] = useState(false);
    const [ selectedStartTime, setSelectedStartTime ] = useState<string | null>(null);

    const [ selectedYear, setSelectedYear ] = useState<number>(0);
    const [ selectedMonth, setSelectedMonth ] = useState<number>(0);
    const [ selectedDate, setSelectedDate ] = useState<number>(0);
    const [ btnStatus, setBtnStatus] = useState(false);

    const [ isBlockDay, setIsBlockDay ] = useState<boolean>(false);

    const [ isAlertModal, setIsAlertModal ] = useState<boolean>(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    const getStoreInfo = async (storeName: string, year: string, month: string, date: string) => {
        
        const reqDate = year + "-" + leadingZeros(month, 2) + "-" + leadingZeros(date, 2);
        const response = await getStoreBookingInfoRequest(storeName, reqDate);
       
        if(response.status === 'success')
        {
            if(response.data === null)
            {
            }
            else
            {
                setInfo(response.data);
            }
           
        }
    }

    const getAdvertisementInfo =async (link:string) => {
        //getAdvertisementInfoRequest

        const response = await getAdvertisementInfoRequest(link);

        if(response.data === null)
        {
            window.location.href =`https://friday11am.site/store/${storename}`
        }
        else
        {
            // console.log(JSON.stringify(response.data))
            if(response.data.state === 1)
            {
                setAdvertisementId((response.data as AdvertisementResponseType).advertisement.id);
                setInfluencerId((response.data as AdvertisementResponseType).advertisement.influencerId);
            }
            else
            {
                window.location.href =`https://friday11am.site/store/${storename}`
            }
            // 
        }
    }

    const [ScrollY, setScrollY] = useState(0); 
    const handleFollow = () => {
        setScrollY(window.pageYOffset); // window 스크롤 값을 ScrollY에 저장\
        if(ScrollY > 150) {
            // 100 이상이면 버튼이 보이게
            setBtnStatus(true);
          } else {
            // 100 이하면 버튼이 사라지게
            setBtnStatus(false);
        }
    }
    
    const handleTop = () => {  // 클릭하면 스크롤이 위로 올라가는 함수
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        setScrollY(0);  // ScrollY 의 값을 초기화
        setBtnStatus(false); // BtnStatus의 값을 false로 바꿈 => 버튼 숨김
    }

    useEffect(() => {
        const watch = () => {
          window.addEventListener('scroll', handleFollow);
        }
        watch(); // addEventListener 함수를 실행
        return () => {
          window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
        }
    })

    useEffect(() => {
        if(link == undefined)
        {

        }
        else
        {
            // load advertisement
            getAdvertisementInfo(link as string);
        }
    }, [link])

    useEffect(() => {
        const cur = new Date();
        setSelectedYear(cur.getFullYear());
        setSelectedMonth(cur.getMonth());
        setSelectedDate(cur.getDate());

        if(storename) getStoreInfo(storename, cur.getFullYear().toString(), (cur.getMonth() + 1).toString(), cur.getDate().toString());
    }, [])

    return (
        <Container>
            {
                calendarView && <Calendar 
                    mode={2}
                    year={selectedYear}
                    month={selectedMonth}
                    date={selectedDate}
                    onClose={() => {
                        setCalendarView(false)
                    }}
                    onSelect={
                        (year, month, date) => {
                            setCalendarView(false)
                            // 달력 이미지 변경, 조회 \
                            // console.log(year + "-" + month + "-" + date)
                            setSelectedYear(year);
                            setSelectedMonth(month);
                            setSelectedDate(date);
                            if(storename) getStoreInfo(storename, year.toString(), (month + 1).toString(), date.toString());
                        }
                    }
                />
            }
            {
                isAlertModal && 
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle('');
                        setAlertDescription('');
                        setIsAlertModal(false);
                    }}
                />
            }
            <Header 
                year={selectedYear}
                month={selectedMonth + 1}
                date={selectedDate}
                onCalender={() => {
                    setCalendarView(true);
                }}
            />
            <div style={{'height': '56px'}}></div>
            {
                info && isRequestModal && <BookingRequestModal 
                    storeId={info.storeInfo.id}
                    year={selectedYear}
                    month={selectedMonth + 1}
                    date={selectedDate}
                    menuList={info.menuList}
                    etcInfoList={info.etcInfoList}
                    startTime={selectedStartTime}
                    uploadDescription={info.storeInfo.photoUploadDescription}
                    isUpload={info.storeInfo.photoUpload}
                    advertisementId={advertisementId}
                    influencerId={influencerId}
                    onClose={() => {
                        setIsRequestModal(false);
                    }}
                    onConfirm={() => {
                        setIsRequestModal(false);
                        setAlertTitle('예약신청이 완료되었습니다.');
                        setAlertDescription('전송된 카카오 알림톡을 확인해주세요.');
                        setIsAlertModal(true);
                        // console.log("예약 대기 페이지로 이동");
                    }}
                />
            }
            {
                info && 
                <>
                    {
                        info.blockDay ? (
                            <>
                                <BlockDayElement 
                                    name={info.storeInfo.name}
                                />
                                <div style={{'height': '8px'}}></div>                   
                                <StoreInfo 
                                    storeInfo={info.storeInfo}
                                />
                                <div style={{'height': '8px'}}></div>
                                <StoreMenuInfo 
                                    menuList={info.menuList}
                                />
                            </>
                            
                        ) : (
                            <>
                                <UserScheduleTable 
                                    name={info.storeInfo.name}
                                    linkLabel={info.storeInfo.linkLabel}
                                    link={info.storeInfo.link}
                                    open={info.open}
                                    close={info.close}
                                    bookingData={info.confirmedBookingList}
                                    breakTimes={info.breakTimeList}
                                    onBooking={(time) => {
                                        setSelectedStartTime(time);
                                        setIsRequestModal(true);
                                    }}
                                />
                                <div style={{'height': '8px'}}></div>                   
                                <StoreInfo 
                                    storeInfo={info.storeInfo}
                                />
                                <div style={{'height': '8px'}}></div>
                                <StoreMenuInfo 
                                    menuList={info.menuList}
                                />
                                <ButtonWrapper>
                                    <PageButton 
                                        text="예약신청하기"
                                        background="#9C27B0"
                                        color="#FFFFFF"
                                        onClick={() => {
                                            setIsRequestModal(true);
                                        }}
                                    />
                                </ButtonWrapper>
                            </>
                        )
                    }
                    
                </>
            }        
        </Container>
    );
}

export default Request;